@import './mixins.scss';
// @import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,300;0,500;1,100;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,300;0,500;0,600;1,100;1,300;1,500;1,600&display=swap');

html {
  font-size: 14px;
  color: #569E3D;
}
@media (max-width: 900px) {
  html { font-size: 12px; }
}
@media (max-width: 400px) {
  html { font-size: 10px; }
}

#nav-holder {
  .menu-item{
    padding-top: 1.5rem;
  }
   z-index: 2;
   color: #FFF;
    list-style: none;
    font-size: 1.25rem;
    position: fixed;
    top:0;
    left:0;
    ul{
      margin: 0;
    }
      li {
        display:block;
        width:auto;
        float:left;
        margin-left:.5rem;
        :hover{
          color: #ff00ff;
        }
      }
      a {
        display:block;
        padding:3px;
        text-decoration:none;
        color: #acacac;
      }
}

/* Type will scale with document */
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2.5rem;
}
h3 {
  font-size: 2rem;
}

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@mixin button-mixin{
  display: inline-block;
	border-radius: $radius-small;
	padding: $standard-padding;
	border: 1px solid $border-colour;
	background-image: linear-gradient(#EEE, #DDD);
}

.button {
@include button-mixin;
  &--state-success {
    @include button-mixin;
    color: #FFF;
	background: #569E3D linear-gradient(#006966, #015c59) repeat-x;
	border-color: #4A993E;
  };
  &--state-danger {
    @include button-mixin;
    background: #3E70AE linear-gradient(#3E70AE, #3a6ba7) repeat-x;

    
    color: #ffffff;
  };
  &.rounded{
    border-radius: $radius-large;
  }
}


.pad-left{
  margin-left: .5rem;
}
.pad-right{
  margin-right: .5rem;
}

.dropdown-cell{
  padding: $standard-padding;
	border: 1px solid $border-colour;
  background-color: rgb(255, 255, 255);
  transition: background-color 0.5s ease ;
  &:hover{
    background-color: rgb(245, 245, 245);
  }
}

input {
  padding: $standard-padding;
	border-radius: $radius-small;
  border: 1px solid;
  border-color: #acacac;
}

.hidden{
  display: none;
}

.biog{
  font-size: 1.2rem;
  font-weight: 300;
  border-radius: 0 50px 50px 0;
  width: 300px;
  background-color: rgb(81 81 76 / 42%);
  z-index: 2;
  position: fixed;
  bottom: 50px;
  left: 0;
  color: #ffffff;
  padding: 30px 30px 30px 60px;
  margin: 0;
  a{
    color: #acacac;
  }
}

.biog-main{
  font-size: 1.2rem;
  font-weight: 300;
  border-radius: 50px 0 50px 0;
  width: 500px;
  background-color: rgb(81 81 76 / 42%);
  z-index: 2;
  position: relative;
  bottom: 50px;
  left: 0;
  color: #ffffff;
  padding: 30px 30px 30px 60px;
  margin: 15% auto;
  a{
    color: #acacac;
  }
}


